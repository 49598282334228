<template>
  <div class="announce-campaign-container mt-1">
    <button
      class="btn mt-3"
      v-for="(item, index) in announceList"
      :key="index"
      @click="_click(item)"
    >
      {{ item.title }}
    </button>
    <div v-if="announceList.length ==0" class="blank-content">ประกาศผู้โชคดีเร็วๆ นี้</div>
  </div>
</template>

<script>
export default {
  data: () => ({
    announceList: [],
  }),
  async mounted() {
    window.loading(true);
    await this.fetchAwards();
    window.loading(false);
  },
  methods: {
    _click(item) {
      this.$router.push({
        name:"announce-lucky-draws-campaign-detail",
        params: { data: item},
      });
    },
    async fetchAwards() {
      
      const res =  await fetch(`${process.env.VUE_APP_API_URL}/lucky-draws-campaign/awards`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
      switch (res.status) {
        case 200: {
          let data = await res.json()
          this.announceList = data
          return;
        }
        case 401:
          console.log("case 401");
          break;
        default: {
          console.log("no case");
          throw "no case";
        }
        
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.announce-campaign-container {
  min-height: 100vh;
  padding: 0px 20px 10px;
  text-align: center;
  .btn {
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #002fb1;
    color: #fff;
    width: 100%;
  }
  .blank-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    height: 180px;
  }
}
</style>